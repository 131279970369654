import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun, faBarcode, faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";




function NavigationMenu({ themeMode, toggleThemeMode, btnScan, toggleScanning, flashOn, toggleFlash }) {
  return (
    <BottomNavigation
      showLabels
      style={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        backgroundColor: themeMode === "dark" ? "#333" : "#f0f0f0",
      }}
    >
      <BottomNavigationAction
        label={btnScan ? "Start Scan" : "Toggle Flash"}
        icon={<FontAwesomeIcon icon={btnScan ? faBarcode : faLightbulb} />}
        onClick={btnScan ? toggleScanning : toggleFlash}
      />
      <BottomNavigationAction
        label={themeMode === "light" ? "Dark Mode" : "Light Mode"}
        icon={<FontAwesomeIcon icon={themeMode === "light" ? faMoon : faSun} />}
        onClick={toggleThemeMode}
      />
    </BottomNavigation>

    
  );
}

export default NavigationMenu;

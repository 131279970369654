import QrScanner from "qr-scanner";
import React, { useState, useRef, useEffect } from "react";
import { Container, Navbar, Row, Col, Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQrcode,
  faPlay,
  faStop,
  faMoon,
  faSun,
  faRedo,
  faExternalLinkAlt,
  faBarcode,
  faTimes,
  faCopy,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import NavigationMenu from "./NavigationMenu";
import { isValidUrl } from "./utils";

function App() {
  const [btnScan, setBtnScan] = useState(true);
  const [themeMode, setThemeMode] = useState("light");
  const [hasilScan, setHasilScan] = useState("");
  const [scanning, setScanning] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isUrlDetected, setIsUrlDetected] = useState(false);
  const videoRef = useRef(null);
  const facingMode = useRef("environment");
  const [flashOn, setFlashOn] = useState(false);
  const stopScanning = () => {
    setScanning(false);
  };

  const toggleScanning = () => {
    setScanning(!scanning);
  };

  const toggleFlash = () => {
    setFlashOn(!flashOn);

    if (videoRef.current) {
      if (flashOn) {
        videoRef.current.removeAttribute("controls");
        videoRef.current.srcObject
          .getVideoTracks()
          .forEach((track) => track.applyConstraints({ advanced: [{ torch: false }] }));
      } else {
        videoRef.current.srcObject
          .getVideoTracks()
          .forEach((track) => track.applyConstraints({ advanced: [{ torch: true }] }));
      }
    }
  };

  const startScanner = async (videoElement) => {
    const scanner = new QrScanner(
      videoElement,
      (result) => {
        setHasilScan(result.data);
        setBtnScan(true);
        setScanning(false);

        setIsUrlDetected(isValidUrl(result.data));

        showModalPopup();
      },
      {
        facingMode: facingMode.current,
        onDecodeError: (error) => {
          console.error(error);
        },
        maxScansPerSecond: 1,
        highlightScanRegion: true,
        highlightCodeOutline: true,
        returnDetailedScanResult: true,
      }
    );

    await scanner.start();
    while (scanning) await new Promise((r) => setTimeout(r, 100));
    scanner.stop();
    scanner.destroy();
  };

  const toggleThemeMode = () => {
    setThemeMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  useEffect(() => {
    if (videoRef.current && scanning) {
      startScanner(videoRef.current);
    }
    return () => {
      setScanning(false);
    };
  }, [scanning]);

  const showModalPopup = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setIsUrlDetected(false);
  };

  const handleOpenUrl = () => {
    window.open(hasilScan, "_blank");
    setShowModal(false);
  };

  const copyToClipboard = () => {
    if (hasilScan) {
      navigator.clipboard.writeText(hasilScan).then(() => {
        console.log("Teks berhasil disalin ke clipboard");
      });
    }
  };

  const getNavbarBgColor = () => {
    const currentTime = getCurrentTime();
    if (currentTime >= 0 && currentTime < 12) {
      return "#28a745";
    } else if (currentTime >= 12 && currentTime < 15) {
      return "#17a2b8";
    } else if (currentTime >= 15 && currentTime < 18) {
      return "#d97031";
    } else {
      return "#800080";
    }
  };

  const getCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours();
    return hours;
  };

  const getGreetingMessage = () => {
    const currentTime = getCurrentTime();
    if (currentTime >= 0 && currentTime < 12) {
      return "Selamat pagi,";
    } else if (currentTime >= 12 && currentTime < 15) {
      return "Selamat siang,";
    } else if (currentTime >= 15 && currentTime < 18) {
      return "Selamat Sore,"
    } else {
      return "Selamat malam,";
    }
  };

  const getCurrentDate = () => {
    const now = new Date();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return now.toLocaleDateString('en-US', options);
  };

  return (
    <div>
      <Navbar className="w-100" style={{ backgroundColor: getNavbarBgColor(), width: "100%" }} variant={themeMode === "dark" ? "dark" : "light"}>
        <Container>
          <Navbar.Brand className="d-flex align-items-center">
            <FontAwesomeIcon icon={faQrcode} className="me-2" size="lg" />
            <span className="d-block text-center fs-6 text-white">
              {getGreetingMessage()}, {getCurrentDate()}
            </span>
          </Navbar.Brand>
        </Container>
      </Navbar>
  
      <div className={`h-100 d-flex flex-column justify-content-between ${themeMode === "dark" ? "bg-dark text-light" : "bg-light text-dark"}`}>
        <Col md={8} lg={6} className="text-center">
          <div className="content App-content mt-2">
            <div id="scanViewContainer" className="d-flex align-items-center justify-content-center">
              <video
                ref={videoRef}
                id="scanView"
              ></video>
            </div>
          </div>
        </Col>
        <div className="navigation-container">
          <NavigationMenu
            themeMode={themeMode}
            toggleThemeMode={toggleThemeMode}
            btnScan={btnScan}
            toggleScanning={toggleScanning}
            flashOn={flashOn.current}
            toggleFlash={toggleFlash}
            scanning={scanning}
          >
            <FontAwesomeIcon icon={faQrcode} />
            {scanning && <Button variant="danger" onClick={stopScanning}>Stop Scan</Button>}
          </NavigationMenu>
        </div>
      </div>
  
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Informasi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isUrlDetected && (
            <div>
              <p>URL Terdeteksi:</p>
              <p>{hasilScan}</p>
              <Button variant="primary" onClick={handleOpenUrl}>
                Open
              </Button>
              <Button variant="primary" onClick={copyToClipboard} className="ml-2">
                <FontAwesomeIcon icon={faCopy} className="me-2" />
              </Button>
            </div>
          )}
          {!isUrlDetected && (
            <div>
              <p>Data Detected:</p>
              <p>{hasilScan}</p>
              <Button variant="primary" onClick={copyToClipboard}>
                Copy
              </Button>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            <FontAwesomeIcon icon={faTimes} className="me-2" />
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>

  );
          }

export default App;